<template>
    <div>
      <!-- Page Heading -->
      <v-toolbar flat>
        <pageHeading pageTitle="Fulltime -- Rostering"/>

        <v-spacer></v-spacer>

        <v-select
            v-model="selSchedule"
            :items="paymentSchedules"
            item-value="id"
            label="Schedule"
            single-line
            :rules="[validate_rules.required]"
            @change="reloadScheduleData()"
        >
            <template slot="selection" slot-scope="data">
                <!-- HTML that describe how select should render selected items -->
                {{ data.item.name }} ,{{ data.item.period_start_date }} - {{ data.item.period_end_date }}, {{ data.item.fulltime_payment_scheme.name }}
            </template>
            <template slot="item" slot-scope="data">
                <!-- HTML that describe how select should render items when the select is open -->
                {{ data.item.name }} ,{{ data.item.period_start_date }} - {{ data.item.period_end_date }}, {{ data.item.fulltime_payment_scheme.name }}
            </template>
        
        </v-select>

      </v-toolbar> 



      <v-divider></v-divider>

        <template>
            <v-dialog v-model="showSlotDialog" v-if="selItem != null">
                <v-card>

                    <v-card-text>
                        <v-simple-table>
                            <template v-slot:default>
                                <tbody>
                                    <tr>
                                        <td>Store Name</td>
                                        <td>{{ selItem.store_name }}</td>
                                    </tr>
                                    <tr>
                                        <td>Employee Name</td>
                                        <td>{{ selItem.employee.name_th }}</td>
                                    </tr>
                                    <tr>
                                        <td>Employee No</td>
                                        <td>{{ selItem.employee.employee_no }}</td>
                                    </tr>
                                    <tr>
                                        <td>Employee Phone</td>
                                        <td>{{ selItem.employee.phone }}</td>
                                    </tr>
                                    <tr>
                                        <td>Slot Date</td>
                                        <td>{{ selSlot.slot_date }}</td>
                                    </tr>
                                    <tr>
                                        <td>Start</td>
                                        <td>{{ selItem.slot_start }}</td>
                                    </tr>
                                    <tr>
                                        <td>End</td>
                                        <td>{{ selItem.slot_end }}</td>
                                    </tr>
                                    <tr>
                                        <td>Working Hour</td>
                                        <td>{{ selItem.online_hour }}</td>
                                    </tr>
                                    <tr>
                                        <td>Amount</td>
                                        <td>{{ selItem.slot_amount }}</td>
                                    </tr>
                                    <tr>
                                        <td>Status</td>
                                        <td>
                                            <v-chip  v-if="selItem[selSlot.slot_date].planing == 'N/A'"> N/A </v-chip>
                                            <v-chip  color="primary" v-if="selItem[selSlot.slot_date].planing == 'Y'" > Y </v-chip>
                                
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeSlotPreview">Cancel</v-btn>
                        <v-btn v-if="canDo && canDo.can.includes('delete') && selItem[selSlot.slot_date].id" color="red darken-1" text @click="clickDeleteRostering">Delete</v-btn>
                    </v-card-actions>
                </v-card>

            </v-dialog>
        </template>
        
      <div class="content-wrap">

            <v-row justify="end" v-show="selSchedule != null">
                <v-col cols="12" sm="6" md="6" >
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" >
                    <!-- Modal Import rostering -->
                    <v-dialog v-model="dialogImport" max-width="500px">
                        <template v-slot:activator="{ on }">
                            <v-btn 
                                v-show="canDo && (canDo.can.includes('import') )"
                                class="mx-2" depressed dark color="primary" v-on="on">
                                <v-icon left dark>mdi-download</v-icon> Import
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                            <span class="headline">Import rostering</span>
                            </v-card-title>

                            <v-card-text>
                            <v-form ref="formImport">
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="12">
                                            <span class="red--text">Only future data will be imported.</span>
                                        </v-col>
                                    </v-row>


                                    

                                    <v-row>
                                        <v-col cols="12" sm="12">
                                            <v-file-input
                                                v-model="importItem.importFile"
                                                :rules="[validate_rules.required,validate_rules.filesize,validate_rules.filetype]"
                                                accept=".xlsx"
                                                placeholder="Browse a xlsx file"
                                                label="Rostering File"
                                                @change="checkImportFile"
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="12">
                                            <v-checkbox
                                                v-model="importItem.ignoreAllWhenError"
                                                :label="'Not import rostering data when some records error'"
                                            ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="12">
                                            <v-radio-group v-model="importItem.modificationMethod" mandatory>
                                                <template v-slot:label>
                                                    <div>Modification method, (the current and pass data are not updated)</div>
                                                </template>
                                                <v-radio
                                                    label="Not update old rostering data"
                                                    value="no"
                                                ></v-radio>
                                                <v-radio
                                                    label="Allow to update old rostering data (specific by user and date)"
                                                    value="remove_employee_day"
                                                ></v-radio>
                                                <v-radio
                                                    label="Remove all rostering data of employee who show in the import file"
                                                    value="remove_employee"
                                                ></v-radio>
                                                <v-radio v-if="userType == 'DM'"
                                                    label="Remove all rostering data before import"
                                                    value="remove_all"
                                                ></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                            <v-alert
                                dense
                                outlined
                                style="overflow:auto"
                                type="error"
                                max-height=100
                                v-if="importResultLog.isShow === true" >
                            {{ importResultLog.message }}
                            </v-alert>

                            </v-card-text>

                            <v-card-actions>
                            <v-spacer></v-spacer>
                                <v-chip class="red--text" v-if="importItem.isImportFileValid === false">{{ importItem.importFileErrorLog }}</v-chip>
                                <v-chip class="green--text" v-if="importItem.isImportFileValid === true"> {{ importItem.importFileErrorLog }}</v-chip>

                                <v-btn color="blue darken-1" text @click="closeImportDialog">Cancel</v-btn>
                                <v-btn v-if="canDo && canDo.can.includes('import') && importItem.isImportFileValid === true" 
                                color="blue darken-1" text @click="clickImportRostering"
                                :disabled="!is_allow_import"
                                >Import</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-btn 
                        v-show="canDo && (canDo.can.includes('export') )"
                        class="mx-2" depressed dark color="primary" @click="clickExport()">
                        <v-icon left dark>mdi-file-export</v-icon> Export
                    </v-btn>
                </v-col>
            </v-row>

           <v-row>
                
              <v-col cols="12" sm="12" md="12" v-show="selSchedule != null" >
                    <v-data-table
                        :headers="headers"
                        :items="rosters"
                        :items-per-page="100"
                        :footer-props="footerProps"
                        class="elevation-1"
                        :sort-by="['store_name']"
                        :sort-desc="[true]"
                        :search="search"

                        dense
                        >

                        <template
                            v-slot:item="{ item }"
                        >
                            <tr>
                                <td>{{ item.store_name }}</td>

                                <td>{{ item.slot_start }}</td>
                                <td>{{ item.slot_end }}</td>
                                <td>{{ item.employee.employee_no }}</td>
                                <td>{{ item.employee.name_th }}</td>
                                <td>{{ item.employee.phone }}</td>
                                <td v-for="slot in headersDate" :key="slot.slot_date" v-bind:class="isToday(slot.slot_date)" >
                                    <v-chip x-small v-if="item[slot.slot_date].planing == 'N/A'" @click="showSlotPreview(item, slot)"> N/A </v-chip>
                                    <v-chip x-small color="primary" v-if="item[slot.slot_date].planing == 'Y'" @click="showSlotPreview(item, slot)"> Y </v-chip>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>

              </v-col>
          </v-row>
      </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import pageHeading from '@/components/common/pageHeading.vue';

export default {
    name: 'FulltimePaymentSchedule',
    data: () => ( {
        expanded: [],
        singleExpand: false,
        dialogImport : false,
        selSchedule : null,
        showReportDialog : false,
        paymentSchedules : [],
        selItem : null,
        selSlot : null,
        userType : "DM",
        showSlotDialog : false,
        importItem: {
            importFile : null,
            modificationMethod : null,
            ignoreAllWhenError : null,
            isImportFileValid : null,
            importFileContent : [],
            importFileErrorLog : ""
        },
        importResultLog: {
            isShow : false,
            message : ""
        },
        is_allow_import : true,
        rosters : [
            
        ],
        headersDate : [],
        search : "",
        payment_records : [],
        footerProps: {'items-per-page-options': [50, 100, 500]},

        headers: [
            
          ],

        menu: {
            start_date: false,
            end_date: false,
            paid_date: false
        },
        newItem: {
            name: null,
            start_date: null,
            end_date: null,
            paid_date: null
        },
        validate_rules : {
            required: v => !!v || 'This field is required',
            filesize:  v => !v || v.size < 2048*1024 || 'Updload size should be less than 2 MB!',
            filetype:  v => {
                if(v){
                var ext = v.name.split('.').pop();
                    return ["xlsx"].includes(ext) || 'Please upload .xlsx file only';
                } else {
                    return 'This field is required'
                }
            }
        },
    }),
    components: {
        pageHeading
    },
    methods: {
         ...mapActions(['genCan', 'getPaymentSchedule', 'checkRosteringFile', 'importRostering', 'getRostering', 'deleteRostering', 'exportPayment','getUserProfile']),
        isToday(inp_date) {
            const moment = require('moment-timezone');

            let ret_class = [];
            if (inp_date == moment().tz('Asia/Bangkok').format("YYYY-MM-DD"))
            {
                ret_class.push('amber');
                ret_class.push('lighten-4');
            }
            return ret_class;
        },
        async clickExport () {
            let result = await this.exportPayment(this.selSchedule);
            if (result)
            {
                alert("The report will be send to your email.");
            }
            else
            {
                alert("Something went wrong.");
            }
        },
        closeImportDialog () {
            this.dialogImport = false
            setTimeout(() => {
                this.importItem = {
                    importFile : null,
                    modificationMethod : null,
                    ignoreAllWhenError : null,
                    isImportFileValid : null,
                    importFileContent : [],
                    importFileErrorLog : ""
                }
                this.$refs.formImport.resetValidation() // prevent validate error show again when click add
            }, 300)
        },
        showSlotPreview(item, slot) {
            this.showSlotDialog = true;
            this.selItem = item;
            this.selSlot = slot;
        },
        closeSlotPreview() {
            this.showSlotDialog = false;
            this.selItem = null;
            this.selSlot = null;
        },
        async clickImportRostering() {
            this.importResultLog = {
                isShow : false,
                message : ""
            }
            this.is_allow_import = false;
            let importResult = await this.importRostering({ importItem : this.importItem, schedule_id : this.selSchedule });
            this.is_allow_import = true;
            console.log(importResult);

            if (importResult.isComplete)
            {
                alert("Import complete, " + importResult.importedRecords.length + "slots were imported and " + importResult.failRecords.length + " record were rejected.")
                if (importResult.failRecords.length > 0)
                this.importResultLog = {
                    isShow : true,
                    message : JSON.stringify(importResult.failRecords, null, 4)
                }
                else
                {
                    this.closeImportDialog();
                }
                this.reloadScheduleData();
            }
            else
            {
                let message = "";
                if (importResult.errorEmployeeNo)
                    message += "No employee no " + importResult.errorEmployeeNo.join(", ") + ".";
                if (importResult.errorStoreCode)
                    message += "No store code " + importResult.errorStoreCode.join(", ") + ".";
                if (importResult.errorMessage)
                    message += importResult.errorMessage;

                this.importResultLog = {
                    isShow : true,
                    message : message
                };
            }
        },
        async checkImportFile() {
            let importCheckResult = await this.checkRosteringFile(this.importItem);
            console.log(importCheckResult);

            this.importItem.importFileContent = importCheckResult.importFileContent;
            this.importItem.importFileErrorLog = importCheckResult.importFileErrorLog;
            this.importItem.isImportFileValid = importCheckResult.isImportFileValid;

        },
        async reloadScheduleData() {
            const lodash = require('lodash');
            const moment = require('moment');


            let schedule = lodash.filter(this.paymentSchedules, {id : this.selSchedule})[0];
            console.log(schedule);
            
            this.headers = [
                { text: 'Store', value: 'store_name', width: 200 },
                { text: 'Start', value: 'slot_start', groupable: false},
                { text: 'End', value: 'slot_end', groupable: false },
                { text: 'Employee No', value: 'employee.employee_no', width: 120 },
                { text: 'Name', value: 'employee.name_th', width: 120 },
                { text: 'Tel', value: 'employee.phone' }
            ]
            this.headersDate = [];

            var start_date = moment(schedule.period_start_date);
            var end_date = moment(schedule.period_end_date);

            for (var m = moment(start_date); m.isSameOrBefore(end_date); m.add(1, 'days')) {
                this.headers.push({ text: m.format('D MMM'), value: m.format("YYYY-MM-DD"), groupable: false });
                this.headersDate.push({slot_date : m.format("YYYY-MM-DD")})

            }

            this.rosters = await this.getRostering(this.selSchedule);
        },
        async clickDeleteRostering() {
            const moment = require('moment-timezone');

            if (this.selSlot.slot_date <= moment().tz("Asia/Bangkok").format("YYYY-MM-DD"))
            {
                alert("The current and old rostering can not be deleted.");
                return;
            }
            if (confirm("Confirm to delete this rostering?"))
            {
                let result = await this.deleteRostering(this.selItem[this.selSlot.slot_date].id);
                if (result.is_complete)
                    await this.reloadScheduleData();
                else
                    alert(result.message);
            }
        }
    },
    async beforeMount() {
        this.genCan();
        this.paymentSchedules = await this.getPaymentSchedule();
    },
    created(){
      this.userType = localStorage.getItem('auth_type');
    },
    computed: {
        ...mapGetters(['canDo','allCategories', 'userProfile'])
    },
    mounted() {
    }
};
</script>